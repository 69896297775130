<template>
  <Tooltip>
    <slot />

    <template #popper>
      <v-html :content="props.content" />
    </template>
  </Tooltip>
</template>

<script setup lang="ts">
import { Tooltip } from "floating-vue"

const props = defineProps<{
  content: string
}>()
</script>

<style lang="scss"></style>
